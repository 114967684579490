import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import '../styles/profile.css';
import Footer from './Footer';

export default function ChangePassword() {
  const { currentUser } = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  
  // State variables to toggle password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      return setError('New passwords do not match.');
    }

    try {
      setLoading(true);
      const credential = EmailAuthProvider.credential(currentUser.email, oldPassword);
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
      setSuccess('Password updated successfully!');
      setError('');
    } catch (error) {
      console.error("Error updating password:", error);
      setError('Failed to update password. Please try again later.');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Header />
      <div className="full-page-center">
        <div className="change-password-container">
          <h2>Change Password</h2>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group-password">
              <label>Old Password</label>
              <input
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
              <span
                className={`toggle-password-p ${showOldPassword ? 'active' : ''}`} // Add 'active' class when password is shown
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            <div className="form-group-password">
              <label>New Password</label>
              <input
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <span
                className={`toggle-password-p ${showNewPassword ? 'active' : ''}`} // Add 'active' class for new password
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            <div className="form-group-password">
              <label>Confirm New Password</label>
              <input
                type={showConfirmNewPassword ? 'text' : 'password'}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
              <span
                className={`toggle-password-p ${showConfirmNewPassword ? 'active' : ''}`} // Add 'active' class for confirm password
                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              >
                <FontAwesomeIcon icon={showConfirmNewPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            <button className="button-password" type="submit" disabled={loading}>
              Update Password
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
