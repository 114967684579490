import React, { useEffect } from 'react';
import Header from './Header';
import '../styles/policy.css';
import Footer from './Footer';

function PrivacyPolicy() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="policy-container">
        <div className="header-policy"></div>
        <h1>Privacy Policy</h1>

        <p>Welcome to the privacy policy (“Privacy Policy”) for minemii.com.</p>

        <p>
          The services available at{' '}
          <a href="http://www.minemii.com">www.minemii.com</a> are provided by
          minemii.com (“minemii.com”, “we”, “us” or “our”).
        </p>

        <p>
          Your privacy is very important to us. Please read this Privacy Policy
          to learn about the ways in which we use and protect your personal
          information.
        </p>

        <p>This Privacy Policy applies from 19 August 2024 for new registering users.</p>

        <h2>Overview</h2>
        <p>
          By accepting this Privacy Policy and the Terms and Conditions, you
          expressly consent to our use and disclosure of your personal
          information in the manner prescribed in this Privacy Policy. If you
          object to this use and disclosure, please do not register. Please note
          that your information will be stored and processed on our computers.
          minemii.com will hold and transmit your information in a safe,
          confidential, and secure environment. minemii.com will never sell or
          rent your personal information to third parties for their marketing
          purposes without your explicit consent. This Privacy Policy applies to
          the <a href="http://www.minemii.com">www.minemii.com</a> website (the
          “Site”).
        </p>

        <h2>Information We Collect</h2>
        <p>
          Our primary goal in collecting personal information is to provide you
          with a safe, efficient, and personalized experience. We only collect
          personal information about you that we consider necessary for this
          purpose. In general, you can browse the Site without telling us who
          you are or revealing any personal information about yourself. Once you
          give us your personal information, you are not anonymous to us. If you
          choose to use our services, we may require you to provide contact and
          identity information, financial information, and other personal
          information as indicated on the forms throughout the Site. Where
          possible, we indicate which fields are required and which fields are
          optional. You always have the option not to provide information by
          choosing not to use a particular service or feature. Under some
          circumstances, we may require some additional financial information,
          such as, but not limited to, credit card details if you are registering
          using an email account at certain email services or registering to sell
          on the Site. We use this financial information to help verify your
          name, address, and other information. We may also use this information
          to charge you for your use of our services. We automatically collect
          and process certain information based upon your use of the Site. We use
          this information to conduct internal research on our users’
          demographics, interests, and behavior to better understand, serve, and
          protect you and our community. This information may include the URL
          that you just came from (whether this URL is on the Site or not), which
          URL you next go to (whether this URL is on the Site or not), browser
          information, and your IP address. We use data collection devices such
          as “cookies” on certain pages of the Site. “Cookies” are small files
          placed on your hard drive that assist us in providing customized
          services and to help promote safer trading. We also offer certain
          features that are only available through the use of a cookie. We also
          use cookies to allow you to enter your password less frequently while
          you are using the Site. Cookies can also help us provide information
          that we tailor to your interests. Most cookies are “session cookies,”
          meaning that they are automatically deleted from your hard drive at the
          end of a session using the Site. You are always free to decline our
          cookies if your browser permits, although in that case you may not be
          able to use certain features on the Site, and you may be required to
          re-enter your password more frequently while you are using the Site.
        </p>

        <h2>Our Use of Your Information</h2>
        <p>
          You agree that we may use personally identifiable information about you
          to improve our marketing and promotional efforts, to analyze site
          usage, improve our content and product offerings, and customize the
          Site’s content, layout, and services. These uses improve the Site and
          better tailor it to meet your needs. You agree that we may use your
          information to contact you and deliver information to you that, in some
          cases, is targeted to your interests, such as targeted banner
          advertisements, administrative notices, product offerings, and
          communications relevant to your use of the Site. By accepting the Terms
          and Conditions and reading this Privacy Policy, you expressly agree to
          receive this information.
        </p>

        <h2>Our Disclosure of Your Information</h2>
        <p>
          As a matter of policy, minemii.com will never sell or rent your
          personal information to third parties for their marketing purposes
          without your explicit consent. However, the following describes some of
          the ways that we may disclose your personally identifiable information:
        </p>

        <ol>
          <li>
            <strong>Advertisers:</strong> We aggregate (gather up data across all
            user accounts) personally identifiable information and disclose such
            information in a non-personally identifiable manner to advertisers and
            other third parties for other marketing and promotional purposes.
            However, in these situations, we do not disclose to these entities
            any information that could be used to identify you personally.
            Certain information, such as your name, email address, password,
            credit card number, and bank account number, are never disclosed to
            third-party advertisers.
          </li>
          <li>
            <strong>Internal Service Providers:</strong> We may use third parties
            that we refer to as internal service providers to facilitate or
            outsource one or more elements of our services (e.g., search, identity
            verification, discussion boards, surveys, affiliate, and reward
            programs) and therefore we may provide some of your personally
            identifiable information directly to them. These internal service
            providers are subject to confidentiality agreements with us and other
            legal restrictions that prohibit their use of the information we
            provide them for any other purpose except to facilitate the specific
            outsourced minemii.com-related operation unless you have explicitly
            agreed or given your prior permission to them for additional uses. In
            some instances, the internal service provider may collect information
            directly from you (such as the situation where we ask an internal
            service provider to conduct a survey for us). In these cases, you will
            be notified of the involvement of the internal service provider, and
            all information disclosures you make will be strictly optional. If you
            provide additional information to an internal service provider
            directly, then their use of your information is governed by their
            applicable privacy policy.
          </li>
          <li>
            <strong>Legal Requests:</strong> minemii.com cooperates with law
            enforcement inquiries, as well as other third parties, to enforce laws
            and third-party rights, such as intellectual property rights and laws
            relating to fraud. Therefore, in response to a verified request by law
            enforcement or other government officials, we can (and you authorize
            us to) disclose personal information such as name, address, telephone
            number, email address, User ID history, as we, in our sole discretion,
            believe necessary or appropriate. Further, we can (and you authorize
            us to) disclose your User ID, name, address, telephone number, email
            address, and company name to minemii.com participants under a
            confidentiality agreement as we, in our sole discretion, believe
            necessary or appropriate in connection with an investigation of
            fraud, intellectual property infringement, or other unlawful activity.
            We cannot ensure that all of your communications with minemii.com and
            other personally identifiable information will never be disclosed in
            ways not otherwise described in this Privacy Policy. For example,
            third parties may unlawfully intercept or access transmissions or
            private communications, or users may abuse or misuse your information
            that they collect from the Site. Therefore, although we use industry
            standard practices to protect your privacy, we do not promise, and you
            should not expect, that your personally identifiable information or
            communications with minemii.com will always remain private.
          </li>
        </ol>

        <h2>Control of Your Password</h2>
        <p>
          You are responsible for all actions taken with your User ID and
          password. Therefore, we do not recommend that you disclose your
          minemii.com password to any third parties. If you choose to share your
          User ID and password or your information with third parties where it is
          necessary for you to do so for them to provide you additional services,
          you are responsible for all actions taken with your User ID and
          password and therefore you should review that third party’s privacy
          policy. If you lose control of your password, you may lose substantial
          control over your personally identifiable information and may be
          subject to legally binding actions taken on your behalf. Therefore, if
          your password or account has been compromised for any reason, you
          should immediately change your password.
        </p>

        <h2>Other Information Collected</h2>
        <p>
          Except as otherwise expressly included in this Privacy Policy, this
          document only addresses the use and disclosure of information we
          collect from you. To the extent that you disclose your information to
          other parties, whether they are buyers or sellers on the Site or other
          sites throughout the Internet, different rules may apply to their use
          or disclosure of the information you disclose to them. To the extent
          that we use third-party advertisers, they adhere to their own privacy
          policies. Since minemii.com does not control the privacy policies of
          third parties, you are subject to the privacy policies of that third
          party. We encourage you to ask questions before you disclose your
          personal information to others.
        </p>

        <h2>Notice</h2>
        <p>
          We may amend this Privacy Policy from time to time to ensure that it
          accurately reflects the regulatory environment and our own data
          collection and disclosure practices. All amended terms automatically
          take effect 30 days after they are initially posted on the Site. If
          your questions are not answered online, you may write to us at:
          minemii.com, Privacy Policy, P.O. Box 843, 5015 AMMAN, Jordan.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
