import React, { useRef, useState,useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import '../styles/Authentication.css';
import BlackImage from './Black.png';  
import RedImage from './RedLogin.png';
import logo from '../components/logo/mine.png'; // Ensure the logo path is correct

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage('Check your email inbox for further instructions.');
    } catch (error) {
      setError('Failed to reset password');
      console.error('Error sending password reset email:', error);
    }

    setLoading(false);
  }

  return (
    <div className="forgot-container">
      <div className="website-info">
        <div className="logo-container">
          <img src={logo} alt="Website Logo" className="logo-image-f" />
        </div>
        {/* <div className="help-text-container">
          <p className="help-text">
            Forgot password? No Worries! Just <br />
            enter your email address and we’ll help you!
          </p>
        </div> */}
      </div>
      <div className="forgot-box">
        <h2 className="forgot-text">Forgot Password?</h2>
        {error && <p className="error-message">{error}</p>}
        {message && <p className="success-message">{message}</p>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input type="email" ref={emailRef} placeholder="E-mail" required />
          </div>
          <button type="submit" className="forgot-button" disabled={loading}>
            Reset Password
          </button>
        </form>
        <div className="login-link">
          Remember your password? <Link to="/login">Log In</Link>
        </div>
      </div>
      <img src={BlackImage} alt="Black" className="black-image" />
      <img src={RedImage} alt="Red" className="Red-image" />
    </div>
  );
}
