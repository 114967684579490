import React,{useEffect} from 'react';
import Header from './Header';
import '../styles/App.css';
import RedImageApp from './Red1App.png';
import RedImageAppL from './RedLeft.png';
import Footer from './Footer';
function About() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <main>
  <section className="content-section">
    <h2>About mine</h2>
    <p>
      Welcome to mine, your personalized video <br />
      platform where you can create and customize video <br />
      education. <br />
      Feel free to explore and enjoy our curated collection <br />
      of videos tailored to various interests and age groups.
    </p>
  </section>
</main>

      <img src={RedImageApp} alt="Red" className="Red-bottom" />
      <img src={RedImageAppL} alt="Red" className="Red-Left" />
      <Footer />
    </div>
  );
}

export default About;
