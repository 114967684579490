import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import '../styles/profile.css';
import Footer from './Footer';

function ChangeEmail() {
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { changeEmail, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    setError('');
    setMessage('');

    try {
      await changeEmail(newEmail, password);
      
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { email: newEmail });

      setMessage('A verification email has been sent to your new email address. Please verify it to complete the email update.');

      setTimeout(() => {
        navigate('/login');
      }, 100);
    } catch (error) {
      console.error('Error updating email:', error);
      setError('Failed to update email. Please ensure your password is correct.');
    }
  }

  return (
    <div>
      <Header />
      <div className="full-page-center">
        <div className="changeemail-container">
          <h2>Change Email</h2>
          <p className="c-email">Your Email: {currentUser && currentUser.email}</p>
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group-email">
              <label>
                New Email:
                <input
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  required
                />
              </label>
              <label>
                Password:
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={showPassword ? 'password-visible' : ''}
                  required
                />
                <span
                  className={`toggle-password ${showPassword ? 'active' : ''}`} // Add 'active' class when password is visible
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </label>
              <button className="button-email" type="submit">Update Email</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ChangeEmail;
