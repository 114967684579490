// src/components/Contact.js
import React,{useEffect} from 'react';
import Header from './Header';
import '../styles/App.css';
import RedImageApp from './Red1App.png';
import RedImageAppL from './RedLeft.png';
import Footer from './Footer';
function Contact() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <main>
  <section className="content-section">
    <h2>Contact Of <br/> mine</h2>
    <p>For any inquiries, feedback, or support, please reach out to us: <br/> 
    Email: contact@mine.com <br/>Phone: +1 (123) 456-7890 <br/> Address: 123 mine Lane, City, Country</p>
  </section>
</main>

      <img src={RedImageApp} alt="Red" className="Red-bottom" />
      <img src={RedImageAppL} alt="Red" className="Red-Left" />
      <Footer/>
    </div>
  );
}

export default Contact;
